import ActionTypes from './types'
import { Constants, I18n } from 'galarm-config'
import { logEvent } from 'firebase/analytics'
import GlobalConfig from '../GlobalConfig'
import { createCloudFunctionUrl } from '../utils/utils'

const resetApp = () => ({
  type: ActionTypes.RESET_APP
})

const setAccountInfo = accountInfo => ({
  type: ActionTypes.SET_ACCOUNT_INFO,
  payload: {
    accountInfo
  }
})

const setSubscription = subscription => ({
  type: ActionTypes.SET_SUBSCRIPTION,
  payload: {
    subscription
  }
})

const setProduct = product => ({
  type: ActionTypes.SET_PRODUCT,
  payload: {
    product
  }
})

const setMembers = members => ({
  type: ActionTypes.SET_MEMBERS,
  payload: {
    members
  }
})

const addMember = member => ({
  type: ActionTypes.ADD_MEMBER,
  payload: {
    member
  }
})

const editMember = member => ({
  type: ActionTypes.EDIT_MEMBER,
  payload: {
    member
  }
})

const removeMember = member => ({
  type: ActionTypes.REMOVE_MEMBER,
  payload: {
    member
  }
})

const addRotation = rotation => ({
  type: ActionTypes.ADD_ROTATION,
  payload: {
    rotation
  }
})

const editRotation = rotation => ({
  type: ActionTypes.EDIT_ROTATION,
  payload: {
    rotation
  }
})

const removeRotation = rotation => ({
  type: ActionTypes.REMOVE_ROTATION,
  payload: {
    rotation
  }
})

const addTeam = team => ({
  type: ActionTypes.ADD_TEAM,
  payload: {
    team
  }
})

const editTeam = team => ({
  type: ActionTypes.EDIT_TEAM,
  payload: {
    team
  }
})

const removeTeam = team => ({
  type: ActionTypes.REMOVE_TEAM,
  payload: {
    team
  }
})

const addAlert = alert => ({
  type: ActionTypes.ADD_ALERT,
  payload: {
    alert
  }
})

const editAlert = alert => ({
  type: ActionTypes.EDIT_ALERT,
  payload: {
    alert
  }
})

const removeAlert = alert => ({
  type: ActionTypes.REMOVE_ALERT,
  payload: {
    alert
  }
})

const addSubscriptionKey = subscriptionKey => ({
  type: ActionTypes.ADD_SUBSCRIPTION_KEY,
  payload: {
    subscriptionKey
  }
})

const editSubscriptionKey = subscriptionKey => ({
  type: ActionTypes.EDIT_SUBSCRIPTION_KEY,
  payload: {
    subscriptionKey
  }
})

const removeSubscriptionKey = subscriptionKey => ({
  type: ActionTypes.REMOVE_SUBSCRIPTION_KEY,
  payload: {
    subscriptionKey
  }
})

const closeIncident =
  // eslint-disable-next-line no-unused-vars
  (incident, enterpriseUser) => async (dispatch, getState) => {
    GlobalConfig.showProgress({
      state: Constants.ProgressStates.IN_PROGRESS,
      message: I18n.t('closingIncident'),
      closeable: false
    })

    if (!enterpriseUser || !enterpriseUser.authToken) {
      GlobalConfig.hideProgress()
      GlobalConfig.showAlert(I18n.t('error'), I18n.t('errorClosingIncident'))
      return
    }

    const cloudFunctionUrl = createCloudFunctionUrl('galarmapi')
    // Post a request to galarmapi/incidents/command/close
    fetch(`${cloudFunctionUrl}/incidents/common/close`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${enterpriseUser.authToken}`
      },
      body: JSON.stringify({
        enterpriseAccountId: incident.enterpriseAccountId,
        alertId: incident.alertId,
        incidentId: incident.id,
        enterpriseUserId: enterpriseUser.id
      })
    })
      .then(() => {
        GlobalConfig.hideProgress()
        logEvent(
          GlobalConfig.analytics,
          Constants.UserAnalyticsEvents.CLOSE_INCIDENT_FROM_UI,
          {}
        )
      })
      .catch(error => {
        console.log('Error closing incident: ', error)
        GlobalConfig.hideProgress()
        GlobalConfig.showAlert(I18n.t('error'), I18n.t('errorClosingIncident'))
      })
  }

const closeIncidents =
  (enterpriseAccountId, alertId, olderThanHours, enterpriseUser) =>
  // eslint-disable-next-line no-unused-vars
  async (dispatch, getState) => {
    GlobalConfig.showProgress({
      state: Constants.ProgressStates.IN_PROGRESS,
      message: I18n.t('closingIncidents'),
      closeable: false
    })

    if (!enterpriseUser || !enterpriseUser.authToken) {
      console.error('Enterprise user not found')
      GlobalConfig.hideProgress()
      GlobalConfig.showAlert(I18n.t('error'), I18n.t('errorClosingIncidents'))
      return
    }

    const cloudFunctionUrl = createCloudFunctionUrl('galarmapi')
    // Post a request to galarmapi/incidents/command/close
    fetch(`${cloudFunctionUrl}/alerts/closeIncidents`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${enterpriseUser.authToken}`
      },
      body: JSON.stringify({
        enterpriseAccountId,
        alertId,
        olderThanHours
      })
    })
      .then(() => {
        GlobalConfig.hideProgress()
        logEvent(
          GlobalConfig.analytics,
          Constants.UserAnalyticsEvents.CLOSE_INCIDENT_FROM_UI,
          {}
        )
      })
      .catch(error => {
        console.error('Error closing incidents: ', error)
        GlobalConfig.hideProgress()
        GlobalConfig.showAlert(I18n.t('error'), I18n.t('errorClosingIncidents'))
      })
  }

export default {
  resetApp,
  setAccountInfo,
  setSubscription,
  setProduct,
  setMembers,
  addMember,
  editMember,
  removeMember,
  addTeam,
  editTeam,
  removeTeam,
  addAlert,
  editAlert,
  removeAlert,
  addSubscriptionKey,
  editSubscriptionKey,
  removeSubscriptionKey,
  addRotation,
  editRotation,
  removeRotation,
  closeIncident,
  closeIncidents
}
