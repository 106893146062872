import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where
} from 'firebase/firestore'
import GlobalConfig from '../GlobalConfig'
import { Constants, I18n } from 'galarm-config'
import {
  Heading,
  Card,
  CardBody,
  Text,
  Box,
  Button,
  ResponsiveContext,
  Tip,
  Layer
} from 'grommet'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import UserContext from './UserContext'
import ActionCreators from '../actions/creators'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { DateTimeUtils } from 'galarm-shared'
import WithRoles from './WithRoles'
import { CircleInformation, Close } from 'grommet-icons'
import AccountSettings from './AccountSettings'

const SubscriptionStatus = ({ enterpriseAccount }) => {
  const subscriptionData = enterpriseAccount.subscriptionData
  const subscriptionStatus = subscriptionData.status
  const onHold = subscriptionData.onHold
  const subscriptionKeys = useSelector(
    state => state.accountInfo.subscriptionKeys
  )

  const numSubscriptionKeys = subscriptionKeys.length
  const subscriptionQty = subscriptionData.quantity

  const getSubscriptionStatus = () => {
    if (onHold) {
      return I18n.t('subscriptionOnHold', {
        numSubscriptionKeys,
        subscriptionQty
      })
    } else if (subscriptionStatus === 'active') {
      return I18n.t('active')
    } else if (subscriptionStatus === 'inactive') {
      return I18n.t('inactive')
    } else if (subscriptionStatus === 'trialing') {
      return I18n.t('trialing')
    } else if (subscriptionStatus === 'incomplete') {
      return I18n.t('incomplete')
    } else if (subscriptionStatus === 'incomplete_expired') {
      return I18n.t('incompleteExpired')
    } else if (subscriptionStatus === 'past_due') {
      return I18n.t('pastDue')
    } else if (subscriptionStatus === 'canceled') {
      return I18n.t('canceled')
    } else if (subscriptionStatus === 'unpaid') {
      return I18n.t('unpaid')
    } else {
      return I18n.t('unknown')
    }
  }

  const getSubscriptionStatusColor = () => {
    if (subscriptionStatus === 'active' || subscriptionStatus === 'trialing') {
      return 'green'
    } else if (subscriptionStatus === 'incomplete') {
      return 'orange'
    } else if (subscriptionStatus === 'incomplete_expired') {
      return 'orange'
    } else if (subscriptionStatus === 'past_due') {
      return 'orange'
    } else if (subscriptionStatus === 'canceled') {
      return 'red'
    } else if (subscriptionStatus === 'unpaid') {
      return 'red'
    } else {
      return 'darkTint'
    }
  }

  const subscriptionStatusString = getSubscriptionStatus()
  const subscriptionStatusColor = getSubscriptionStatusColor()

  return <Text color={subscriptionStatusColor}>{subscriptionStatusString}</Text>
}

const OrganizationSummary = ({
  item,
  onChooseOrganization,
  showOrgOperations,
  onChangeOwner,
  onDeleteOrganization
}) => {
  const { user } = useContext(UserContext)
  const size = useContext(ResponsiveContext)

  const [roles, setRoles] = useState([])
  const navigate = useNavigate()
  const currentlyChosenEnterpriseAccountId = window.localStorage.getItem(
    'enterpriseAccountId'
  )

  const [showAccountSettings, setShowAccountSettings] = useState(false)
  const displayAccountSettings = () => {
    setShowAccountSettings(true)
  }
  const hideAccountSettings = () => {
    setShowAccountSettings(false)
  }

  useEffect(() => {
    async function loadRolesData() {
      try {
        const enterpriseAccount = await getDoc(
          doc(GlobalConfig.firestoreDb, 'enterpriseAccounts', item.id)
        ).then(enterpriseAccountSnapshot => enterpriseAccountSnapshot.data())

        if (!enterpriseAccount) {
          return []
        }

        const userRoles = []
        if (enterpriseAccount.owner === user.uid) {
          userRoles.push(Constants.ENTERPRISE_USER_ROLES.OWNER)
        }

        const member = await getDoc(
          doc(
            GlobalConfig.firestoreDb,
            'enterpriseAccounts',
            item.id,
            'members',
            user.uid
          )
        ).then(memberSnapshot => memberSnapshot.data())

        if (member) {
          userRoles.push(member.role)
        }

        setRoles(userRoles)
      } catch (error) {
        console.error('Error finding member roles', error)
      }
    }
    loadRolesData()
  }, [])

  const onOpenBilling = () => {
    navigate('/app/billing', {
      state: { customerId: item.subscriptionData.customerId }
    })
  }

  const onOpenSettings = () => {
    displayAccountSettings()
  }

  const onRenewSubscription = () => {
    navigate('/app/buy', {
      state: { enterpriseAccountId: item.id }
    })
  }

  const planType = Constants.ENTERPRISE_PLAN_TYPES.find(
    planType =>
      planType.value === (item.subscriptionData.planType || 'enterprise')
  ).label

  console.log('OrganizationSummary', item, roles, planType)

  return (
    <Box>
      <Card background="textBackgroundColor" margin="small">
        <CardBody direction={size === 'small' ? 'column' : 'row'}>
          <Box pad="small" flex margin={{ right: 'large' }}>
            <Text margin="xsmall">
              <Text weight={500}>{I18n.t('name') + ': '}</Text>
              {item.name}
            </Text>
            {item.subscriptionData.subscriptionId ? (
              <Box>
                <Text margin="xsmall">
                  <Text weight={500}>{I18n.t('planType') + ': '}</Text>
                  {planType}
                </Text>
                <Box direction="row" align="center">
                  <Text margin="xsmall">
                    <Text weight={500}>{I18n.t('plan') + ': '}</Text>
                    {I18n.t('enterprisePlan', {
                      quantity: item.subscriptionData.quantity,
                      interval: item.subscriptionData.interval
                    })}
                  </Text>
                  <WithRoles
                    requiredRoles={[
                      Constants.ENTERPRISE_USER_ROLES.OWNER,
                      Constants.ENTERPRISE_USER_ROLES.ADMIN
                    ]}
                    assignedRoles={roles}>
                    <Tip
                      dropProps={{
                        width: 'medium'
                      }}
                      content={I18n.t('updatePlanInstructions')}>
                      <CircleInformation />
                    </Tip>
                  </WithRoles>
                </Box>
                <Text margin="xsmall">
                  <Text weight={500}>{I18n.t('status') + ': '}</Text>
                  <SubscriptionStatus enterpriseAccount={item} />
                </Text>
                <Text margin="xsmall">
                  <Text weight={500}>{I18n.t('renewsOn') + ': '}</Text>
                  {DateTimeUtils.getDateAsString(
                    item.subscriptionData.subscriptionEnd * 1000
                  )}
                </Text>
                <Text margin="xsmall">
                  <Text weight={500}>{I18n.t('yourRoles') + ': '}</Text>
                  {roles.join(', ')}
                </Text>
              </Box>
            ) : (
              <Text margin="xsmall">
                {I18n.t('noPaymentInformationAvailable')}
              </Text>
            )}
          </Box>
          <Box background="lightTint" pad="small">
            {item.id === currentlyChosenEnterpriseAccountId ? (
              <Text margin="xsmall">{I18n.t('currentOrganization')}</Text>
            ) : (
              <Button
                plain
                color="primary"
                margin="xsmall"
                size="small"
                label={I18n.t('useThisOrganization')}
                onClick={event => {
                  event.stopPropagation()
                  onChooseOrganization(item)
                }}
              />
            )}
            {item.subscriptionData.status === 'past_due' && (
              <WithRoles
                requiredRoles={[
                  Constants.ENTERPRISE_USER_ROLES.OWNER,
                  Constants.ENTERPRISE_USER_ROLES.ADMIN
                ]}
                assignedRoles={roles}>
                <Button
                  plain
                  color="primary"
                  margin="xsmall"
                  size="small"
                  label={I18n.t('fixPayment')}
                  onClick={event => {
                    event.stopPropagation()
                    onOpenBilling()
                  }}
                />
              </WithRoles>
            )}
            {item.subscriptionData.status === 'canceled' && (
              <WithRoles
                requiredRoles={[
                  Constants.ENTERPRISE_USER_ROLES.OWNER,
                  Constants.ENTERPRISE_USER_ROLES.ADMIN
                ]}
                assignedRoles={roles}>
                <Button
                  plain
                  color="primary"
                  margin="xsmall"
                  size="small"
                  label={I18n.t('renewSubscription')}
                  onClick={event => {
                    event.stopPropagation()
                    onRenewSubscription()
                  }}
                />
              </WithRoles>
            )}
            {!item.subscriptionData.subscriptionId && (
              <WithRoles
                requiredRoles={[
                  Constants.ENTERPRISE_USER_ROLES.OWNER,
                  Constants.ENTERPRISE_USER_ROLES.ADMIN
                ]}
                assignedRoles={roles}>
                <Button
                  plain
                  color="primary"
                  margin="xsmall"
                  size="small"
                  label={I18n.t('addPaymentInformation')}
                  onClick={event => {
                    event.stopPropagation()
                    onRenewSubscription()
                  }}
                />
              </WithRoles>
            )}
            {roles.includes(Constants.ENTERPRISE_USER_ROLES.OWNER) &&
              showOrgOperations && (
                <Button
                  plain
                  color="primary"
                  margin="xsmall"
                  size="small"
                  label={I18n.t('changeOwner')}
                  onClick={event => {
                    event.stopPropagation()
                    onChangeOwner(item)
                  }}
                />
              )}
            {roles.includes(Constants.ENTERPRISE_USER_ROLES.OWNER) &&
              currentlyChosenEnterpriseAccountId !== item.id &&
              showOrgOperations && (
                <Button
                  plain
                  color="primary"
                  margin="xsmall"
                  size="small"
                  label={I18n.t('deleteOrganization')}
                  onClick={event => {
                    event.stopPropagation()
                    onDeleteOrganization(item.id)
                  }}
                />
              )}
            {showOrgOperations && (
              <WithRoles
                requiredRoles={[
                  Constants.ENTERPRISE_USER_ROLES.OWNER,
                  Constants.ENTERPRISE_USER_ROLES.ADMIN
                ]}
                assignedRoles={roles}>
                <Button
                  plain
                  color="primary"
                  margin="xsmall"
                  size="small"
                  label={I18n.t('openBillingPortal')}
                  onClick={event => {
                    event.stopPropagation()
                    onOpenBilling()
                  }}
                />
              </WithRoles>
            )}
            {showOrgOperations && (
              <WithRoles
                requiredRoles={[
                  Constants.ENTERPRISE_USER_ROLES.OWNER,
                  Constants.ENTERPRISE_USER_ROLES.ADMIN
                ]}
                assignedRoles={roles}>
                <Button
                  plain
                  color="primary"
                  margin="xsmall"
                  size="small"
                  label={I18n.t('settings')}
                  onClick={event => {
                    event.stopPropagation()
                    onOpenSettings()
                  }}
                />
              </WithRoles>
            )}
          </Box>
        </CardBody>
      </Card>
      {showAccountSettings && (
        <Layer
          background="lightTint"
          onEsc={hideAccountSettings}
          onClickOutside={hideAccountSettings}>
          <Box margin="medium">
            <AccountSettings
              enterpriseAccountId={item.id}
              onClose={hideAccountSettings}
            />
            <Box style={{ position: 'absolute', top: 20, right: 20 }}>
              <Close onClick={hideAccountSettings} />
            </Box>
          </Box>
        </Layer>
      )}
    </Box>
  )
}

const Organizations = ({ showOrgOperations = false, addError }) => {
  const { user } = useContext(UserContext)
  const enterpriseAccountId = window.localStorage.getItem('enterpriseAccountId')

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [organizations, setOrganizations] = useState([])

  useEffect(() => {
    async function loadOrganizationsData() {
      try {
        const enterpriseUserData = await getDoc(
          doc(GlobalConfig.firestoreDb, 'enterpriseUsers', user.uid)
        ).then(docSnap => docSnap.data())

        let organizationsData = []
        const organizations = enterpriseUserData.organizations
        if (organizations?.length > 0) {
          const enterpriseAccountsQuery = query(
            collection(GlobalConfig.firestoreDb, 'enterpriseAccounts'),
            // in filter only allows upto 10 orgs
            where('id', 'in', organizations.slice(-10))
          )
          const querySnapshot = await getDocs(enterpriseAccountsQuery)
          querySnapshot.forEach(doc => {
            organizationsData.push(doc.data())
          })
          setOrganizations(organizationsData)
        }
      } catch (error) {
        console.error(error)
        addError(error.message)
      }
    }
    loadOrganizationsData()
  }, [])

  const onChooseOrganization = organization => {
    window.localStorage.setItem('enterpriseAccountId', organization.id)
    dispatch(ActionCreators.resetApp())
    navigate('/app/org', { replace: true })
  }

  const onChangeOwner = enterpriseAccount => {
    navigate(`/app/org/changeowner`, {
      state: { enterpriseAccountId: enterpriseAccount.id }
    })
  }

  const onDeleteOrganization = async enterpriseAccountIdToBeDeleted => {
    GlobalConfig.showAlert(
      I18n.t('deleteOrganization'),
      I18n.t('deleteOrganizationDescription'),
      [
        {
          text: I18n.t('cancel')
        },
        {
          text: I18n.t('delete'),
          onPress: () => {
            deleteOrganizationCore(enterpriseAccountIdToBeDeleted)
          }
        }
      ]
    )
  }

  const deleteOrganizationCore = async enterpriseAccountIdToBeDeleted => {
    console.log('deleteOrganization', enterpriseAccountIdToBeDeleted)
    GlobalConfig.showProgress({
      state: Constants.ProgressStates.IN_PROGRESS,
      message: I18n.t('deletingOrganization'),
      closeable: false
    })
    try {
      const functions = getFunctions()
      const deleteOrganization = httpsCallable(functions, 'deleteOrganization')
      await deleteOrganization({
        enterpriseAccountId: enterpriseAccountIdToBeDeleted
      })
      setOrganizations(
        organizations.filter(org => org.id !== enterpriseAccountIdToBeDeleted)
      )
      GlobalConfig.hideProgress()
    } catch (error) {
      GlobalConfig.hideProgress()
      console.error(error)
      addError(error.message)
    }
  }

  return (
    <Box pad="small" gap="small" width={{ max: 'large' }} flex={{ shrink: 0 }}>
      <Box direction="row" justify="between">
        <Heading margin="small" level={3}>
          {I18n.t('yourOrganizations')}
        </Heading>
      </Box>
      {organizations.length === 0 ? (
        <Text margin="small">{I18n.t('noOrganizations')}</Text>
      ) : (
        organizations.map((organization, index) => (
          <OrganizationSummary
            key={index}
            item={organization}
            onChooseOrganization={onChooseOrganization}
            currentOrganization={enterpriseAccountId}
            showOrgOperations={showOrgOperations}
            onChangeOwner={onChangeOwner}
            onDeleteOrganization={onDeleteOrganization}
          />
        ))
      )}
    </Box>
  )
}

export default Organizations
