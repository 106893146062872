import {
  Box,
  Button,
  Form,
  FormField,
  Heading,
  Select,
  TextInput
} from 'grommet'
import React, { forwardRef, useEffect, useState } from 'react'
import ErrorProvider from './ErrorProvider'
import GlobalConfig from '../GlobalConfig'
import { Constants, I18n } from 'galarm-config'
import {
  stringNotEmpty,
  validateEmail,
  validateFirstName,
  validateLastName,
  validatePhoneNumber
} from '../utils/validations'
import {
  arrayUnion,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where
} from 'firebase/firestore'
import { StringUtils, Utils } from 'galarm-shared'
import FormFieldWithAllBorders from './FormFieldWithAllBorders'
import { getFunctions, httpsCallable } from 'firebase/functions'
import {
  equalTo,
  get,
  orderByChild,
  ref,
  query as dbQuery
} from 'firebase/database'
import { logEvent } from 'firebase/analytics'
import PhoneInput from 'react-phone-number-input'

import 'react-phone-number-input/style.css'
import { SecondaryText } from 'web-components'
import { FirebaseProxy } from 'galarm-ps-api'

const roleOptions = [
  { label: I18n.t('admin'), value: Constants.ENTERPRISE_USER_ROLES.ADMIN },
  { label: I18n.t('viewer'), value: Constants.ENTERPRISE_USER_ROLES.VIEWER }
]

const PhoneNumberTextInput = forwardRef((props, ref) => {
  return (
    <TextInput
      plain
      id="mobileNumber"
      name="mobileNumber"
      ref={ref}
      disabled={props.disabled}
    />
  )
})
PhoneNumberTextInput.displayName = 'PhoneNumberTextInput'

const EditMember = ({ isEdit, member, onAssignKey, onClose }) => {
  const enterpriseAccountId = window.localStorage.getItem('enterpriseAccountId')

  const INITIAL_STATE = isEdit
    ? {
        mobileNumber: member.mobileNumber,
        firstName: member.firstName,
        lastName: member.lastName,
        email: member.email,
        role: member.role,
        appUid: member.appUid,
        id: member.id
      }
    : {
        mobileNumber: '',
        firstName: '',
        lastName: '',
        email: '',
        role: Constants.ENTERPRISE_USER_ROLES.VIEWER
      }

  const [value, setValue] = useState(INITIAL_STATE)
  const [defaultCountryCode, setDefaultCountryCode] = useState('')

  const [errors, setErrors] = useState([])

  const addError = error => setErrors(errors.concat([error]))
  const onClearError = errorToClear => {
    const newErrors = errors.filter(error => error !== errorToClear)
    setErrors(newErrors)
  }

  useEffect(() => {
    if (isEdit) {
      return
    }

    const ipstackKey = FirebaseProxy.remoteConfig()
      .getValue('ipstackKey')
      .asString()

    if (!ipstackKey) {
      return
    }

    var url = `https://api.ipstack.com/check?access_key=${ipstackKey}`
    fetch(url)
      .then(response => {
        return response.json()
      })
      .then(responseJson => {
        if (
          responseJson.country_code &&
          responseJson.location &&
          responseJson.location.calling_code
        ) {
          setDefaultCountryCode(responseJson.country_code)
          setValue({
            ...value,
            mobileNumber: '+' + responseJson.location.calling_code
          })
        }
      })
      .catch(error => {
        console.error('error', error)
      })
  }, [])

  const onEditMember = async ({ value }) => {
    try {
      const { mobileNumber, firstName, lastName, email, role, appUid, id } =
        value
      const sanitizedMobileNumber = Utils.sanitizePhoneNumber(mobileNumber)

      let memberAppUid, memberId
      if (!isEdit) {
        GlobalConfig.showProgress({
          state: Constants.ProgressStates.IN_PROGRESS,
          message: I18n.t('checkingIfMemberIsAppUser'),
          closeable: false
        })

        const usersSnapshot = await get(
          dbQuery(
            ref(GlobalConfig.realtimeDb, 'users'),
            orderByChild('mobileNumberWithCC'),
            equalTo(sanitizedMobileNumber)
          )
        )

        if (!usersSnapshot.val()) {
          throw new Error(I18n.t('noUserFoundWithMobileNumber'))
        }

        const members = Object.values(usersSnapshot.val()).filter(
          user => !user.accountInactive
        )

        if (members.length > 1) {
          throw new Error(I18n.t('multipleUsersWithSameMobileNumber'))
        }

        const member = members[0]

        if (!member) {
          throw new Error(I18n.t('noUserFoundWithMobileNumber'))
        }

        memberAppUid = member.id

        const memberQuery = query(
          collection(
            GlobalConfig.firestoreDb,
            'enterpriseAccounts',
            enterpriseAccountId,
            'members'
          ),
          where('appUid', '==', memberAppUid)
        )
        const memberQuerySnapshot = await getDocs(memberQuery)
        const memberExists = !memberQuerySnapshot.empty

        if (memberExists) {
          throw new Error(I18n.t('memberWithPhoneNumberAlreadyAdded'))
        }

        const userWithEmailQuery = query(
          collection(
            GlobalConfig.firestoreDb,
            'enterpriseAccounts',
            enterpriseAccountId,
            'members'
          ),
          where('email', '==', email)
        )
        const userWithEmailQuerySnapshot = await getDocs(userWithEmailQuery)
        const userWithEmailExists = !userWithEmailQuerySnapshot.empty

        if (userWithEmailExists) {
          throw new Error(I18n.t('memberWithEmailAlreadyAdded'))
        }

        const enterpriseUserQuery = query(
          collection(GlobalConfig.firestoreDb, 'enterpriseUsers'),
          where('email', '==', email)
        )
        const enterpriseUserQuerySnapshot = await getDocs(enterpriseUserQuery)

        if (enterpriseUserQuerySnapshot.size === 1) {
          let enterpriseUser
          enterpriseUserQuerySnapshot.forEach(
            doc => (enterpriseUser = doc.data())
          )

          memberId = enterpriseUser.id
          await updateDoc(
            doc(GlobalConfig.firestoreDb, 'enterpriseUsers', memberId),
            {
              organizations: arrayUnion(enterpriseAccountId)
            }
          )
        } else if (enterpriseUserQuerySnapshot.size > 1) {
          throw new Error(I18n.t('multipleUsersWithSameEmail'))
        } else {
          GlobalConfig.showProgress({
            state: Constants.ProgressStates.IN_PROGRESS,
            message: I18n.t('creatingAnAccountForMember'),
            closeable: false
          })
          const displayName = StringUtils.createDisplayName(firstName, lastName)

          const functions = getFunctions()
          const createUserWithEmail = httpsCallable(
            functions,
            'createUserWithEmail'
          )
          const result = await createUserWithEmail({
            firstName,
            lastName,
            email: email,
            enterpriseAccountId,
            displayName: displayName
          })
          memberId = result.data.uid
        }
      } else {
        memberId = id
        memberAppUid = appUid
      }

      GlobalConfig.showProgress({
        state: Constants.ProgressStates.IN_PROGRESS,
        message: I18n.t('settingMemberDetails'),
        closeable: false
      })

      const memberToAdd = {
        id: memberId,
        firstName,
        lastName,
        email,
        mobileNumber: sanitizedMobileNumber,
        role: role,
        appUid: memberAppUid
      }

      await setDoc(
        doc(
          GlobalConfig.firestoreDb,
          'enterpriseAccounts',
          enterpriseAccountId,
          'members',
          memberId
        ),
        memberToAdd,
        { merge: true }
      )

      // Also assign a key to member if it is a new member
      !isEdit && onAssignKey(memberToAdd)

      GlobalConfig.hideProgress()

      logEvent(
        GlobalConfig.analytics,
        isEdit
          ? Constants.UserAnalyticsEvents.ADD_MEMBER
          : Constants.UserAnalyticsEvents.EDIT_MEMBER,
        {}
      )

      onClose()
    } catch (error) {
      console.error(error)
      addError(error.message)
      GlobalConfig.hideProgress()
    }
  }

  return (
    <ErrorProvider
      errors={errors}
      getErrorMessage={error => error}
      onClearError={onClearError}>
      <Box align="center" flex={{ shrink: 0 }}>
        <Heading level={4}>
          {isEdit ? I18n.t('editMember') : I18n.t('addMember')}
        </Heading>
        <Box
          background="white"
          pad="small"
          round="small"
          margin="medium"
          width="large">
          <Form
            value={value}
            onChange={nextValue => setValue(nextValue)}
            validate="submit"
            onSubmit={userData => {
              onEditMember(userData)
            }}>
            <FormField
              disabled={isEdit}
              htmlFor="mobileNumber"
              name="mobileNumber"
              label={
                <SecondaryText weight={500}>
                  {I18n.t('mobileNumber')}
                </SecondaryText>
              }
              validate={validatePhoneNumber}>
              <PhoneInput
                disabled={isEdit}
                style={{ marginLeft: 10 }}
                international
                onChange={mobileNumber => setValue({ ...value, mobileNumber })}
                countrySelectProps={{ unicodeFlags: true }}
                inputComponent={PhoneNumberTextInput}
                defaultCountry={defaultCountryCode}
              />
            </FormField>
            <Box direction="row" gap="medium">
              <FormField
                flex
                htmlFor="firstName"
                name="firstName"
                label={
                  <SecondaryText weight={500}>
                    {I18n.t('firstName')}
                  </SecondaryText>
                }
                validate={validateFirstName}>
                <TextInput id="firstName" name="firstName" />
              </FormField>
              <FormField
                flex
                htmlFor="lastName"
                name="lastName"
                label={
                  <SecondaryText weight={500}>
                    {I18n.t('lastName')}
                  </SecondaryText>
                }
                validate={validateLastName}>
                <TextInput id="lastName" name="lastName" />
              </FormField>
            </Box>
            <FormField
              disabled={isEdit}
              htmlFor="email"
              name="email"
              label={
                <SecondaryText weight={500}>{I18n.t('email')}</SecondaryText>
              }
              validate={validateEmail}>
              <TextInput id="email" name="email" disabled={isEdit} />
            </FormField>
            <FormFieldWithAllBorders
              htmlFor="role"
              name="role"
              label={
                <SecondaryText weight={500}>{I18n.t('role')}</SecondaryText>
              }
              validate={stringNotEmpty.bind(null, I18n.t('role'))}>
              <Select
                id="role"
                name="role"
                options={roleOptions}
                placeholder={I18n.t('selectPlaceholder')}
                labelKey="label"
                valueKey={{ key: 'value', reduce: true }}
                onChange={({ option }) => {
                  setValue({
                    ...value,
                    role: option.value
                  })
                }}
              />
            </FormFieldWithAllBorders>
            <Box direction="row" gap="medium" justify="center" margin="medium">
              <Button
                type="submit"
                primary
                style={{ color: 'white' }}
                label={isEdit ? I18n.t('save') : I18n.t('add')}
              />
            </Box>
          </Form>
        </Box>
      </Box>
    </ErrorProvider>
  )
}

export default EditMember
