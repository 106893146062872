import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore'
import { I18n } from 'galarm-config'
import { Box, DataTable, Text } from 'grommet'
import React, { useEffect, useState } from 'react'
import GlobalConfig from '../GlobalConfig'
import { DateTimeUtils } from 'galarm-shared'

const AlertLogs = ({ alert }) => {
  const enterpriseAccountId = window.localStorage.getItem('enterpriseAccountId')
  const [logs, setLogs] = useState([])

  useEffect(() => {
    async function loadLogs() {
      const logsQuery = query(
        collection(
          GlobalConfig.firestoreDb,
          'enterpriseAccounts',
          enterpriseAccountId,
          'alerts',
          alert.id,
          'logs'
        ),
        orderBy('timestamp', 'desc'),
        limit(100)
      )
      const logsSnapshot = await getDocs(logsQuery)
      const newLogs = []
      logsSnapshot.forEach(logSnapshot => newLogs.push(logSnapshot.data()))
      setLogs(newLogs)
    }
    loadLogs()
  }, [])

  return (
    <Box width={{ max: 'xlarge' }} height={{ max: 'large' }} overflow="auto">
      <DataTable
        background={{
          body: ['white', 'light-2']
        }}
        columns={[
          {
            property: 'timestamp',
            header: <Text>{I18n.t('timestamp')}</Text>,
            render: datum => (
              <Text>{DateTimeUtils.getDateTimeAsString(datum.timestamp)}</Text>
            )
          },
          {
            property: 'message',
            header: <Text>{I18n.t('message')}</Text>
          }
        ]}
        data={logs}
      />
    </Box>
  )
}

export default AlertLogs
