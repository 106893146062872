import React, { useState } from 'react'
import { Box, Text, Button, TextInput } from 'grommet'
import { Constants, I18n } from 'galarm-config'
import { DateTimeUtils } from 'galarm-shared'
import { Refresh } from 'grommet-icons'
import SecondaryText from './SecondaryText'

const intervals = [
  [
    {
      label: I18n.t('minsInterval', { count: 1 }),
      value: Constants.MSEC_IN_MINUTE
    },
    {
      label: I18n.t('minsInterval', { count: 15 }),
      value: 15 * Constants.MSEC_IN_MINUTE
    }
  ],
  [
    {
      label: I18n.t('minsInterval', { count: 5 }),
      value: 5 * Constants.MSEC_IN_MINUTE
    },
    {
      label: I18n.t('minsInterval', { count: 30 }),
      value: 30 * Constants.MSEC_IN_MINUTE
    }
  ],
  [
    {
      label: I18n.t('minsInterval', { count: 10 }),
      value: 10 * Constants.MSEC_IN_MINUTE
    },
    {
      label: I18n.t('hoursInterval', { count: 1 }),
      value: Constants.MSEC_IN_HOUR
    }
  ]
]

const ChooseDurationContainer = props => {
  const [duration, setDuration] = useState(
    props.initialDuration ? props.initialDuration : 0
  )

  const onDurationChange = interval => {
    let isValidDuration = checkIfDurationIsValid(interval)
    // If the duration is invalid and there is a handler to handle invalid duration, then invoke the handler
    if (!isValidDuration && props.handleInvalidDurationSelection) {
      props.handleInvalidDurationSelection(duration)
    }

    const newDuration = isValidDuration ? duration + interval.value : duration

    setDuration(newDuration)
  }

  const onClear = () => {
    setDuration(0)
  }

  const getDurationAsString = duration => {
    return DateTimeUtils.getDurationAsString(
      duration,
      I18n.t('minutes', { count: 0 }),
      props.considerSeconds
    )
  }

  const isValidDuration = duration => {
    return props.isValidDuration ? props.isValidDuration(duration) : duration
  }

  const checkIfDurationIsValid = interval => {
    let isValidDuration = true
    const finalDuration = duration + interval.value
    if (props.isValidDuration) {
      isValidDuration = props.isValidDuration(finalDuration)
    } else if (finalDuration >= Constants.MSEC_IN_DAY) {
      isValidDuration = false
    }
    return isValidDuration
  }

  const onChangeLabelValueForDuration = event => {
    props.onChangeLabelValueForDuration(event.target.value)
  }

  const displayText = props.getDisplayTextForDuration(duration)

  const durationIntervals = props.intervals ? props.intervals : intervals

  return (
    <Box pad="medium" style={{ width: '400px' }}>
      <Text alignSelf="center" size="xlarge" weight="bold">
        {props.title}
      </Text>
      <Box
        direction="row"
        justify="center"
        align="center"
        gap="small"
        margin="small">
        <Text size="large">
          {props.getDurationAsString
            ? props.getDurationAsString(duration)
            : getDurationAsString(duration)}
        </Text>
        <Box onClick={() => duration && onClear()}>
          <Refresh color={duration ? 'primary' : 'mediumTint'} />
        </Box>
      </Box>
      <Box
        direction="row"
        gap="medium"
        margin={{ vertical: '20px' }}
        justify="center"
        alignContent="center">
        {durationIntervals.map((columns, i) => (
          <Box key={i} gap="medium">
            {columns.map(interval => (
              <Box
                background="lightTint"
                pad="small"
                border
                round
                key={interval.label}
                onClick={() => onDurationChange(interval)}>
                <Text color="textColor" textAlign="center">
                  {interval.label}
                </Text>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
      <Box pad="xsmall">
        <Text
          size="small"
          textAlign="center"
          color={duration ? 'textColor' : 'darkTint'}>
          {duration ? displayText : I18n.t('chooseDuration')}
        </Text>
      </Box>
      {props.additionalHelperText && (
        <Box pad="xsmall">
          <SecondaryText textAlign="center">
            {props.additionalHelperText(duration)}
          </SecondaryText>
        </Box>
      )}
      {duration > 0 && props.optionalLabelForDuration && (
        <Box direction="row" align="center" pad={{ vertical: 'small' }}>
          <Text>{props.optionalLabelForDuration}</Text>
          <TextInput
            size="small"
            placeholder={props.getOptionalLabelPlaceholderForDuration(duration)}
            value={props.optionalLabelValueForDuration}
            onChange={onChangeLabelValueForDuration}
            margin="small"
          />
        </Box>
      )}
      <Box
        flex
        direction="row"
        margin={{ top: '20px' }}
        gap="medium"
        justify="end">
        <Button onClick={props.onClose} label={I18n.t('cancel')} />
        <Button
          onClick={() => {
            props.onSaveDuration(duration)
          }}
          disabled={!isValidDuration(duration)}
          label={I18n.t('save')}
        />
      </Box>
    </Box>
  )
}

export default ChooseDurationContainer
