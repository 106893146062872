import { getFirestore } from 'firebase/firestore'
import { getDatabase, ref } from 'firebase/database'
import { getAnalytics } from 'firebase/analytics'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { initializeApp } from 'firebase/app'
import { Constants } from 'galarm-config'

console.log('Environment', __DEV__ ? 'Development' : 'Production')

const GlobalConfig = (function () {
  let firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    projectId: process.env.REACT_APP_PROJECT_ID,
    appId: process.env.REACT_APP_APP_ID
  }

  console.log('Firebase Config', firebaseConfig)

  // Initialize Firebase
  const firebaseApp = initializeApp(firebaseConfig)

  const realtimeDb = getDatabase(firebaseApp)
  const firestoreDb = getFirestore(firebaseApp)
  const functions = getFunctions(firebaseApp)
  const analytics = getAnalytics(firebaseApp)
  // Use the firebase functions running locally during development
  // Commnent if you want to run the hosted version
  __DEV__ && connectFunctionsEmulator(functions, 'localhost', 5001)

  // To create a test release, set test to !__DEV__ and testUser to 'vg'
  return {
    rootFirebaseRef: ref(realtimeDb),
    realtimeDb,
    firestoreDb,
    analytics,
    defaultIncidentsRetentionDuration: 7 * Constants.MSEC_IN_DAY
  }
})()

export default GlobalConfig
