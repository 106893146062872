import {
  collectionGroup,
  doc,
  getDoc,
  onSnapshot,
  query,
  where
} from 'firebase/firestore'
import { Constants, I18n } from 'galarm-config'
import { DateTimeUtils } from 'galarm-shared'
import {
  Box,
  Button,
  CheckBox,
  Data,
  DataTable,
  Heading,
  Layer,
  Menu,
  Text
} from 'grommet'
import { Close, MoreVertical } from 'grommet-icons'
import { isEmpty } from 'lodash'
import React, { useContext, useEffect, useRef, useState } from 'react'
import ViewIncidentEscalation from './ViewIncidentEscalation'
import { useDispatch } from 'react-redux'
import ActionCreators from '../actions/creators'
import UserContext from './UserContext'
import WithRoles from './WithRoles'
import IncidentNotes from './IncidentNotes'
import GlobalConfig from '../GlobalConfig'

const INCIDENT_TABLE_VIEWS = [
  {
    name: 'All Incidents',
    properties: {}
  },
  {
    name: 'Open Incidents',
    properties: {
      state: ['open']
    }
  },
  {
    name: 'Closed Incidents',
    properties: {
      state: ['closed']
    }
  }
]

const IncidentsTable = () => {
  const enterpriseAccountId = window.localStorage.getItem('enterpriseAccountId')
  const enterpriseUser = useRef(null)

  const dispatch = useDispatch()
  const { user, userRoles } = useContext(UserContext)

  // eslint-disable-next-line no-unused-vars
  const [incidentsRange, setIncidentsRange] = useState(
    GlobalConfig.defaultIncidentsRetentionDuration
  )
  const [incidents, setIncidents] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [searchText, setSearchText] = useState('')
  const [filterOptions, setFilterOptions] = useState({ state: ['open'] })
  const [view, setView] = useState('Open Incidents')
  const [sortOptions, setSortOptions] = useState({
    property: 'reportedAt',
    direction: 'desc'
  })

  useEffect(() => {
    const fetchEnterpriseUser = async () => {
      const enterpriseUserDoc = doc(
        GlobalConfig.firestoreDb,
        'enterpriseUsers',
        user.uid
      )
      const enterpriseUserSnap = await getDoc(enterpriseUserDoc)
      enterpriseUser.current = enterpriseUserSnap.data()
    }
    fetchEnterpriseUser()
  }, [user])

  useEffect(() => {
    const whereClauses = [
      where('enterpriseAccountId', '==', enterpriseAccountId)
    ]
    if (!isEmpty(filterOptions)) {
      Object.keys(filterOptions).forEach(key => {
        if (key === 'reportedAtDate') {
          const date = DateTimeUtils.getDateFromDateString(filterOptions[key])
          whereClauses.push(
            where('timestamp', '>=', date),
            where('timestamp', '<', date + Constants.MSEC_IN_DAY)
          )
        } else {
          whereClauses.push(where(key, 'in', filterOptions[key]))
        }
      })
      // If reportedAtDate is not set, then get last 30 days incidents
      if (!filterOptions.reportedAtDate) {
        whereClauses.push(where('timestamp', '>', Date.now() - incidentsRange))
      }
    } else {
      whereClauses.push(where('timestamp', '>', Date.now() - incidentsRange))
    }

    const allIncidentsQuery = query(
      collectionGroup(GlobalConfig.firestoreDb, 'incidents'),
      ...whereClauses
    )

    const unsubscribe = onSnapshot(allIncidentsQuery, snapshot => {
      const allIncidents = []
      snapshot.forEach(incidentRef => {
        allIncidents.push(incidentRef.data())
      })
      setIncidents(
        allIncidents.map(incident => ({ ...incident, selected: false }))
      )
    })

    return () => unsubscribe()
  }, [enterpriseAccountId, incidentsRange, searchText, filterOptions])

  const updateView = props => {
    console.log('updateView', props)
    const { properties = {}, view = '', search = '' } = props
    setView(view)
    setSearchText(search)
    setFilterOptions(properties)
  }

  const [showIncidentEscalationDialog, setShowIncidentEscalationDialog] =
    useState({})
  const displayIncidentEscalationDialog = incident =>
    setShowIncidentEscalationDialog({ incident })
  const hideIncidentEscalationDialog = () => setShowIncidentEscalationDialog({})

  const [showIncidentNotesDialog, setShowIncidentNotesDialog] = useState({})
  const displayIncidentNotesDialog = incident =>
    setShowIncidentNotesDialog({ incident })
  const hideIncidentNotesDialog = () => setShowIncidentNotesDialog({})

  const onCloseIncident = async incident => {
    dispatch(ActionCreators.closeIncident(incident, enterpriseUser.current))
  }

  const onCloseIncidents = async () => {
    const selectedIncidents = incidents.filter(
      incident => incident.selected && incident.state === 'open'
    )
    selectedIncidents.forEach(incident => onCloseIncident(incident))
  }

  const getMenuItemsForIncident = incident => {
    const menuItems = [
      {
        label: I18n.t('viewIncidentNotes'),
        onClick: () => displayIncidentNotesDialog(incident)
      },
      {
        label: I18n.t('viewIncidentEscalation'),
        onClick: () => displayIncidentEscalationDialog(incident)
      }
    ]

    // Only show close incident if it is not already closed
    if (!incident.closedAt) {
      menuItems.push({
        label: I18n.t('closeIncident'),
        onClick: () => onCloseIncident(incident)
      })
    }

    return menuItems
  }

  const onSelectIncident = (incident, selected) => {
    const newIncidents = incidents.map(inc => {
      if (inc.id === incident.id) {
        return { ...inc, selected }
      }
      return inc
    })
    setIncidents(newIncidents)
  }

  const isIncidentValid = incident => 'alertId' in incident

  // let allIncidentsLastAssignees = []
  const tableData = incidents
    .map(incident => {
      // let lastAssignees = [],
      //   lastAssignedAt = ''
      // if (incident.alarms && incident.alarms.length) {
      //   const latestAlarm = Utils.objArrayMax(incident.alarms, 'date')
      //   lastAssignees = latestAlarm.members.map(member => ({
      //     label: member.name,
      //     value: member.id
      //   }))
      //   lastAssignedAt = DateTimeUtils.getDateTimeAsString(latestAlarm.date)
      // }
      // allIncidentsLastAssignees = unionWith(
      //   allIncidentsLastAssignees,
      //   lastAssignees,
      //   (a, b) => a.value === b.value
      // )

      return {
        id: incident.id,
        identifier: incident.identifier || incident.id,
        enterpriseAccountId: incident.enterpriseAccountId,
        alertId: incident.alertId,
        alertName: incident.alertName,
        reportedAtDate: DateTimeUtils.getDateAsString(incident.timestamp),
        reportedAt: incident.timestamp,
        state: incident.state,
        acknowledgements: incident.acknowledgements,
        closedBy: incident.closedBy,
        closedAt: incident.closedTimestamp,
        closedTimestamp: incident.closedTimestamp,
        recoveredAt: incident.recoveredTimestamp,
        recoveredTimestamp: incident.recoveredTimestamp,
        selected: incident.selected,
        timestamp: incident.timestamp,
        message: incident.message,
        additionalNotes: incident.additionalNotes,
        priority: incident.priority || 3
      }
    })
    .sort((a, b) => {
      if (a[sortOptions.property] < b[sortOptions.property]) {
        return sortOptions.direction === 'asc' ? -1 : 1
      }
      if (a[sortOptions.property] > b[sortOptions.property]) {
        return sortOptions.direction === 'asc' ? 1 : -1
      }
      return 0
    })
    .filter(incident => {
      if (searchText) {
        return Object.values(incident).some(value => {
          return value
            ?.toString()
            .toLowerCase()
            .includes(searchText.toLowerCase())
        })
      }
      return true
    })

  const showClosedSelectedIncidentsButton = tableData.some(
    incident => incident.selected && incident.state === 'open'
  )

  return (
    <Box pad="small" width="xlarge" flex={{ shrink: 0 }}>
      <Box direction="row" gap="small" justify="between" align="center">
        <Heading margin="small" level={3}>
          {I18n.t('incidents')}
        </Heading>
        {showClosedSelectedIncidentsButton && (
          <WithRoles
            requiredRoles={[
              Constants.ENTERPRISE_USER_ROLES.OWNER,
              Constants.ENTERPRISE_USER_ROLES.ADMIN
            ]}
            assignedRoles={userRoles}>
            <Button
              primary
              label={I18n.t('closeSelectedIncidents')}
              style={{ color: 'white' }}
              onClick={onCloseIncidents}
            />
          </WithRoles>
        )}
      </Box>
      <Box pad="small">
        <Data
          views={INCIDENT_TABLE_VIEWS}
          view={
            view
              ? view
              : {
                  properties: filterOptions,
                  search: searchText
                }
          }
          data={tableData}
          toolbar
          onView={updateView}
          total={incidents.length}
          properties={{
            state: {
              label: 'Status',
              options: [
                { label: I18n.t('notAcknowledged'), value: 'open' },
                { label: I18n.t('acknowledged'), value: 'acknowledged' },
                { label: I18n.t('closed'), value: 'closed' },
                { label: I18n.t('recovered'), value: 'recovered' }
              ],
              sort: false,
              search: false
            },
            alertName: {
              label: 'Listener',
              sort: false,
              search: false
            },
            reportedAtDate: {
              label: 'Reported At',
              sort: false,
              search: false
            },
            priority: {
              label: 'Priority',
              options: [
                { label: 'Critical', value: 1 },
                { label: 'High', value: 2 },
                { label: 'Medium', value: 3 },
                { label: 'Low', value: 4 },
                { label: 'Info', value: 5 }
              ],
              sort: false,
              search: false
            }
          }}
          messages={{
            dataSummary: {
              filtered: '{filteredTotal} results of {total} incidents',
              filteredSingle: '{filteredTotal} result of {total} incident',
              items: 'incidents',
              itemsSingle: 'incident',
              selected: '{selected} selected',
              total: '{total} incidents',
              totalSingle: '{total} incident'
            }
          }}>
          <DataTable
            primaryKey="id"
            paginate={true}
            columns={[
              {
                property: 'identifier',
                header: <Text weight={500}>Identifier</Text>,
                render: datum => (
                  <Box direction="row" align="center" justify="between">
                    {datum.state === 'open' ? (
                      <Box direction="row" gap="small" justify="between">
                        <CheckBox
                          checked={datum.selected}
                          onChange={event =>
                            onSelectIncident(datum, event.target.checked)
                          }
                        />
                        <Text>{datum.identifier}</Text>
                      </Box>
                    ) : (
                      <Box direction="row" gap="small" justify="end">
                        <CheckBox disabled checked />
                        <Text>{datum.identifier}</Text>
                      </Box>
                    )}
                    <Menu
                      dropBackground="lightTint"
                      alignSelf="start"
                      size="small"
                      icon={<MoreVertical color="textColor" />}
                      items={getMenuItemsForIncident(datum)}
                    />
                  </Box>
                )
              },
              {
                property: 'alertName',
                header: <Text weight={500}>Listener</Text>
              },
              {
                property: 'reportedAt',
                header: <Text weight={500}>Reported At</Text>,
                render: datum => (
                  <Text>
                    {DateTimeUtils.getDateTimeAsString(datum.reportedAt)}
                  </Text>
                )
              },
              {
                property: 'priority',
                header: <Text weight={500}>Priority</Text>,
                render: datum => (
                  <Text>
                    {Constants.INCIDENT_PRIORITY_LABELS[datum.priority] ||
                      Constants.INCIDENT_PRIORITY_LABELS[3]}
                  </Text>
                )
              },
              {
                property: 'state',
                header: <Text weight={500}>Status</Text>,
                render: datum => (
                  <Text>{Constants.INCIDENT_STATUS_LABELS[datum.state]}</Text>
                )
              },
              {
                property: 'closedAt',
                header: <Text weight={500}>Closed At</Text>,
                render: datum => (
                  <Text>
                    {datum.closedAt
                      ? DateTimeUtils.getDateTimeAsString(datum.closedAt)
                      : datum.state === 'recovered'
                      ? DateTimeUtils.getDateTimeAsString(datum.recoveredAt)
                      : ''}
                  </Text>
                )
              }
            ]}
            sort={{
              direction: sortOptions.direction,
              external: true,
              property: sortOptions.property
            }}
            background={{ header: 'light-4', body: ['light-1', 'white'] }}
            border={true}
            pad="small"
            onSort={setSortOptions}
          />
        </Data>
      </Box>
      {!isEmpty(showIncidentEscalationDialog) &&
        isIncidentValid(showIncidentEscalationDialog.incident) && (
          <Layer
            background="lightTint"
            onEsc={hideIncidentEscalationDialog}
            onClickOutside={hideIncidentEscalationDialog}>
            <Box margin="medium">
              <ViewIncidentEscalation
                incident={showIncidentEscalationDialog.incident}
                onClose={hideIncidentEscalationDialog}
              />
              <Box style={{ position: 'absolute', top: 20, right: 20 }}>
                <Close onClick={hideIncidentEscalationDialog} />
              </Box>
            </Box>
          </Layer>
        )}
      {!isEmpty(showIncidentNotesDialog) &&
        isIncidentValid(showIncidentNotesDialog.incident) && (
          <Layer
            background="lightTint"
            onEsc={hideIncidentNotesDialog}
            onClickOutside={hideIncidentNotesDialog}
            margin="medium">
            <Box margin="medium">
              <IncidentNotes
                incident={showIncidentNotesDialog.incident}
                onClose={hideIncidentNotesDialog}
              />
              <Box style={{ position: 'absolute', top: 20, right: 20 }}>
                <Close onClick={hideIncidentNotesDialog} />
              </Box>
            </Box>
          </Layer>
        )}
    </Box>
  )
}

export default IncidentsTable
