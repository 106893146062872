import { createSelector } from 'reselect'
import Utils from '../utils/Utils'
import { GlobalConfig } from 'galarm-config'
import { Constants } from 'galarm-config'
import { getPrevAndNextDatesForAnAlarmWrtDate } from '../utils/AlarmUtils'
import arrayCompact from 'lodash/compact'
import AlarmUtils from '../utils/AlarmUtils'
import DateTimeUtils from '../utils/DateTimeUtils'
import { formatNumber } from 'libphonenumber-js'
import ActionCreators from '../actions/creators'
import objGet from 'lodash/get'
import arrCompact from 'lodash/compact'
import moment from 'moment-timezone'
import isEqual from 'lodash/isEqual'
import omitBy from 'lodash/omitBy'
import { ConfigManager } from 'galarm-ps-api'
import { Platform } from 'react-native'

const getContacts = state => state.contacts.contacts
const getInvitedContacts = state => state.contacts.invitedContacts
const getPhoneContacts = state => state.contacts.phoneContacts
const getBlockedContacts = state => state.userInfo.blockedContacts
const getChecklists = state => state.alarms.checklists
const getOwnAlarms = state => state.alarms.alarms
const getRecentlyDeletedAlarms = state => state.alarms.recentlyDeletedAlarms
const getParticipantAlarms = state => state.backupAlarms.alarms
const getGroups = state => state.groups.groups
const getUserName = state => state.userInfo.name
const getUserThumbnail = state =>
  state.userInfo.images ? state.userInfo.images.avatarThumbnailUrl : undefined
const getUserMobileNumber = state => state.userInfo.mobileNumber
const getConnections = state => state.connections.connections
const getAlarmsCount = state => state.userInfo.alarmsCount
const getAlarmCategories = state => state.userInfo.alarmCategories
const getAlarmAcknowledgements = state =>
  state.alarmActions.alarmAcknowledgements
const getAlarmStatusChanges = state => state.alarmActions.alarmStatusChanges
const getPurchases = state => state.userInfo.purchases
const getAppState = state => state.appState
const getMaxAllowedAlarms = state => state.userInfo.maxAllowedAlarms
const getSystemAlerts = state => state.userInfo.systemAlerts

const getAlarmIdFromProps = (state, props) => props.alarmId
const getChecklistIdIdFromProps = (state, props) => props.checklistId
const getContactIdFromProps = (state, props) => props.contactId
const getGroupIdFromProps = (state, props) => props.groupId
const getIdFromProps = (state, props) => props.id
const getAlarmConversation = (state, props) =>
  state.conversations.alarms[props.alarmId] || {}
const getAlarmsConversations = state => state.conversations.alarms
const getUserSettings = state => state.userSettings
const getNotificationSettings = state => state.userSettings.notificationSettings
const getEnterpriseSubscriptions = state =>
  state.userInfo.enterpriseSubscriptions

const resolveContactName = (
  contact,
  username,
  mobileNumber,
  contacts,
  connections
) => {
  let storedContact = Utils.getObjectWithId(contacts, contact.id)
  let storedConnection = Utils.getObjectWithId(connections, contact.id)
  // Sanity check
  if (contact.id === GlobalConfig.uid) {
    storedContact = {
      name: username,
      mobileNumber: mobileNumber,
      known: true
    }
  }
  if (storedContact !== undefined) {
    return {
      ...contact,
      name: storedContact.name,
      mobileNumber: storedContact.mobileNumber,
      known: true
    }
  } else if (storedConnection !== undefined) {
    const completeMobileNumber =
      '+' + storedConnection.countryCode + storedConnection.mobileNumber
    const formattedMobileNumber = formatNumber(
      completeMobileNumber,
      'International'
    )
    return {
      ...contact,
      name: storedConnection.name,
      mobileNumber: formattedMobileNumber,
      known: false
    }
  } else {
    // This is not expected. We have come across a connection that we don't know of
    // Add a connection to the person ourselves
    GlobalConfig.store &&
      GlobalConfig.store.dispatch(
        ActionCreators.addMisingConnection(contact.id)
      )
    return {
      ...contact,
      known: false
    }
  }
}

const resolveGroupMembers = (
  members,
  username,
  mobileNumber,
  contacts,
  connections
) => {
  const resolvedMembers = members.map(member =>
    resolveContactName(member, username, mobileNumber, contacts, connections)
  )
  return resolvedMembers
}

export const alarmCategoriesSelector = createSelector(
  [getAlarmCategories],
  alarmCategories => {
    let filteredAlarmCategories = {}
    // alarmCategories shouldn't be undefined but saw an
    // error on Sentry where it was reported as undefined.
    alarmCategories &&
      Object.keys(alarmCategories).forEach(id => {
        const alarmCategory = alarmCategories[id]
        if (alarmCategory.id && alarmCategory.name) {
          filteredAlarmCategories[id] = alarmCategory
        }
      })
    filteredAlarmCategories[Constants.UNCATEGORIZED_ALARM_CATEGORY_ID] =
      Constants.UNCATEGORIZED_ALARM_CATEGORY
    return filteredAlarmCategories
  }
)

export const ringerSettingsSelector = createSelector(
  [getUserSettings],
  userSettings => ({
    alarmRingtone: userSettings.alarmRingtone,
    vibrate: userSettings.vibrate,
    volume: userSettings.volume,
    ringtoneDuration: userSettings.ringtoneDuration,
    autoSnooze: userSettings.autoSnooze,
    autoSnoozeDuration: userSettings.autoSnoozeDuration,
    autoSnoozeCount: userSettings.autoSnoozeCount,
    ringOnVibrate: userSettings.ringOnVibrate,
    announceAlarmName: userSettings.announceAlarmName,
    criticalAlerts: userSettings.criticalAlerts,
    criticalAlertsVolume: userSettings.criticalAlertsVolume
  })
)

export const mutedNotificationsSelector = createSelector(
  [getNotificationSettings],
  notificationSettings => {
    return Object.values(
      notificationSettings || GlobalConfig.defaultNotificationSettings
    ).filter(obj => !obj)
  }
)

export const contactListSelector = createSelector(
  [getContacts, getBlockedContacts],
  (contacts, blockedContacts) => {
    return contacts.map(contact => ({
      ...contact,
      blocked: blockedContacts.includes(contact.id)
    }))
  }
)

export const invitedContactListSelector = createSelector(
  [getInvitedContacts],
  invitedContacts => {
    return invitedContacts.map(invitedContact => ({
      ...invitedContact,
      invitationPending: true
    }))
  }
)

export const sortedOtherContactListSelector = createSelector(
  [getPhoneContacts, getContacts, getInvitedContacts],
  (allContacts, galarmContacts, invitedContacts) => {
    const otherContacts = []
    const allGalarmContacts = galarmContacts.concat(invitedContacts)
    allContacts.forEach(otherContact => {
      const index = allGalarmContacts.findIndex(function (contact) {
        return isEqual(contact.mobileNumber, otherContact.phoneNumber)
      })
      if (index === -1) {
        otherContacts.push({
          name: Utils.createContactName(otherContact),
          mobileNumber: otherContact.phoneNumber,
          label: otherContact.phoneNumberLabel
        })
      }
    })

    const sortedOtherContacts = otherContacts.sort((contact1, contact2) => {
      return Utils.alphabeticallyCompareObjsBasedOnAPropCI(
        contact1,
        contact2,
        'name'
      )
    })

    return sortedOtherContacts
  }
)

export const sortedContactListSelector = createSelector(
  [contactListSelector, invitedContactListSelector],
  (contacts, invitedContacts) => {
    const sortedContacts = contacts.sort((contact1, contact2) => {
      return Utils.alphabeticallyCompareObjsBasedOnAPropCI(
        contact1,
        contact2,
        'name'
      )
    })

    const sortedInvitedContacts = invitedContacts.sort((contact1, contact2) => {
      return Utils.alphabeticallyCompareObjsBasedOnAPropCI(
        contact1,
        contact2,
        'name'
      )
    })

    return sortedContacts.concat(sortedInvitedContacts)
  }
)

export const groupListSelector = createSelector(
  [
    getGroups,
    getUserName,
    getUserMobileNumber,
    contactListSelector,
    getConnections
  ],
  (groups, username, mobileNumber, contacts, connections) => {
    return groups.map(group => {
      // For the backups of the alarm, if it is a group, then resolve the name
      // of each member is the group using user's group configuration
      if (group !== undefined && group.members !== undefined) {
        const resolvedMembers = resolveGroupMembers(
          group.members,
          username,
          mobileNumber,
          contacts,
          connections
        )
        group = {
          ...group,
          members: resolvedMembers
        }
      }

      return {
        ...group
      }
    })
  }
)

// Delegating to get methods for performance
export const ownAlarmsSelector = state => getOwnAlarms(state)
export const participantAlarmsSelector = state => getParticipantAlarms(state)

export const numGroupAlarmsSelector = createSelector(
  [ownAlarmsSelector],
  ownAlarms =>
    ownAlarms.filter(alarm => alarm.type === Constants.AlarmTypes.SIMULTANEOUS)
      .length
)

export const numBuddyAlarmsSelector = createSelector(
  [ownAlarmsSelector],
  ownAlarms =>
    ownAlarms.filter(alarm => alarm.type === Constants.AlarmTypes.RECIPIENT)
      .length
)

// Not exported because it doesn't contain information about whether alarm is a
// own alarm or participant alarm. If needed, check whether the information is needed
// and use accordingly.
const simpleAllAlarmsSelector = createSelector(
  [ownAlarmsSelector, participantAlarmsSelector],
  (ownAlarms, participantAlarms) => ownAlarms.slice().concat(participantAlarms)
)

export const alertIncidentsSelector = createSelector(
  [participantAlarmsSelector],
  participantAlarms => {
    return participantAlarms
      .map(alarm => ({
        ...alarm,
        alarmCategory: Constants.AlarmCategories.PARTICIPANT_ALARM
      }))
      .filter(
        alarm =>
          alarm.source ===
            Constants.AlarmCreationSources.CREATED_BY_ENTERPRISE_ALERT_API &&
          alarm.state !== Constants.ParticipantStates.INACTIVE
      )
      .sort((alarm1, alarm2) => alarm2.date - alarm1.date)
  }
)

export const allAlarmsSelector = createSelector(
  [ownAlarmsSelector, participantAlarmsSelector, getAlarmAcknowledgements],
  // Although alarmAcknowledgements is not used in the selector,
  // it is passed because we want to recompute the alarms when acknowledgements change
  // eslint-disable-next-line no-unused-vars
  (ownAlarms, participantAlarms, alarmAcknowledgements) => {
    const alarmEffectiveDates = {}
    const startTime = Date.now()
    const alarms = ownAlarms
      .map(alarm => ({
        ...alarm,
        alarmCategory: Constants.AlarmCategories.MY_ALARM
      }))
      .concat(
        participantAlarms
          .filter(alarm => {
            // Filter out the alarms for which you are an inactive participant or the alarm is disabled and has not been marked as done
            // by the creator/recipient recently. For the latter case, we only want to consider personal and buddy alarm
            if (
              alarm.state === Constants.ParticipantStates.INACTIVE ||
              (alarm.status === false &&
                !AlarmUtils.isCurrentAlarmOccurrenceAcknowledged(alarm))
            ) {
              return false
            }

            // Don't show backup alarms which will not ring in future
            const currDate = Date.now()
            const { prevDate, nextDate } =
              AlarmUtils.getPrevAndNextDatesForAnAlarmWrtDate(
                currDate,
                alarm.date,
                alarm.endDate,
                alarm.repeatType,
                alarm.repeat,
                alarm.creatorTimezone
              )
            if (
              nextDate ||
              prevDate + GlobalConfig.alarmPreviousOccurrenceThreshold >
                currDate
            ) {
              return true
            }

            return false
          })
          .map(alarm => ({
            ...alarm,
            alarmCategory: Constants.AlarmCategories.PARTICIPANT_ALARM
          }))
      )
      .sort((alarm1, alarm2) => {
        // Sort the alarms in the order of the next time they will ring
        let alarm1EffectiveDate = alarmEffectiveDates[alarm1.id]
        if (!alarm1EffectiveDate) {
          alarm1EffectiveDate = AlarmUtils.getCurrentDateForAlarm(alarm1)
          alarmEffectiveDates[alarm1.id] = alarm1EffectiveDate
        }
        let alarm2EffectiveDate = alarmEffectiveDates[alarm2.id]
        if (!alarm2EffectiveDate) {
          alarm2EffectiveDate = AlarmUtils.getCurrentDateForAlarm(alarm2)
          alarmEffectiveDates[alarm2.id] = alarm2EffectiveDate
        }
        return alarm2EffectiveDate - alarm1EffectiveDate
      })
    const endTime = Date.now()
    console.tron.log('Calculating the alarms took ' + (endTime - startTime))
    return alarms
  }
)

export const enabledAlarmsSelector = createSelector(
  [allAlarmsSelector],
  allAlarms => {
    return allAlarms.filter(alarm => {
      if (!alarm.status) {
        return false
      } else if (
        alarm.alarmCategory === Constants.AlarmCategories.PARTICIPANT_ALARM
      ) {
        if (alarm.responseStatus === Constants.REJECT_ALARM) {
          return false
        } else if (GlobalConfig.ringParticipantAlarmsByDefault === false) {
          return alarm.responseStatus === Constants.ACCEPT_ALARM
        }
      }
      return true
    })
  }
)

export const nextFiveAlarmsSelector = createSelector(
  [enabledAlarmsSelector],
  allAlarms => {
    return allAlarms
      .filter(alarm =>
        AlarmUtils.getNextDateForAlarm(
          alarm.date,
          alarm.endDate,
          alarm.repeatType,
          alarm.repeat,
          alarm.creatorTimezone
        )
      )
      .sort((alarm1, alarm2) => {
        const alarm1NextDate = AlarmUtils.getNextDateForAlarm(
          alarm1.date,
          alarm1.endDate,
          alarm1.repeatType,
          alarm1.repeat,
          alarm1.creatorTimezone
        )
        const alarm2NextDate = AlarmUtils.getNextDateForAlarm(
          alarm2.date,
          alarm2.endDate,
          alarm2.repeatType,
          alarm2.repeat,
          alarm2.creatorTimezone
        )
        return alarm1NextDate - alarm2NextDate
      })
      .slice(0, 5)
  }
)

export const nextFiveAlarmsThatShouldRingSelector = createSelector(
  [enabledAlarmsSelector],
  allAlarms => {
    return allAlarms
      .filter(alarm => AlarmUtils.getNextRingDateForAlarm(alarm))
      .map(alarm => ({
        ...alarm,
        date: AlarmUtils.getNextRingDateForAlarm(alarm)
      }))
      .sort((alarm1, alarm2) => alarm1.date - alarm2.date)
      .slice(0, 5)
  }
)

export const todayAlarmsSelector = createSelector(
  [enabledAlarmsSelector],
  allAlarms => {
    return allAlarms.filter(alarm => {
      const todayDate = DateTimeUtils.getTodayDate()

      const { nextDate } = AlarmUtils.getPrevAndNextDatesForAnAlarmWrtDate(
        todayDate,
        alarm.date,
        alarm.endDate,
        alarm.repeatType,
        alarm.repeat,
        alarm.creatorTimezone
      )

      return !!nextDate && DateTimeUtils.isDateToday(nextDate)
    })
    // Today's alarms are shown in sections and sorting is done within each section
  }
)

export const tomorrowAlarmsSelector = createSelector(
  [enabledAlarmsSelector],
  allAlarms => {
    return allAlarms
      .filter(alarm => {
        const tomorrowDate = DateTimeUtils.getTomorrowDate()

        const { nextDate } = AlarmUtils.getPrevAndNextDatesForAnAlarmWrtDate(
          tomorrowDate,
          alarm.date,
          alarm.endDate,
          alarm.repeatType,
          alarm.repeat,
          alarm.creatorTimezone
        )

        return !!nextDate && DateTimeUtils.isDateTomorrow(nextDate)
      })
      .sort((alarm1, alarm2) => {
        const tomorrowDate = DateTimeUtils.getTomorrowDate()
        const { nextDate: nextDate1 } =
          AlarmUtils.getPrevAndNextDatesForAnAlarmWrtDate(
            tomorrowDate,
            alarm1.date,
            alarm1.endDate,
            alarm1.repeatType,
            alarm1.repeat,
            alarm1.creatorTimezone
          )
        const { nextDate: nextDate2 } =
          AlarmUtils.getPrevAndNextDatesForAnAlarmWrtDate(
            tomorrowDate,
            alarm2.date,
            alarm2.endDate,
            alarm2.repeatType,
            alarm2.repeat,
            alarm2.creatorTimezone
          )
        return nextDate1 - nextDate2
      })

    // Show tomorrow's alarms in ascending order of time for tomorrow
  }
)

export const sortedGroupListSelector = createSelector(
  [groupListSelector],
  groups => {
    return groups.slice().sort((group1, group2) => {
      return Utils.alphabeticallyCompareObjsBasedOnAPropCI(
        group1,
        group2,
        'name'
      )
    })
  }
)

export const sortedGroupListSelectorForAlarmParticipant = createSelector(
  [sortedGroupListSelector],
  groups => {
    return groups
      .slice()
      .filter(group => group.isRemovedGroup !== true)
      .map(group => {
        const members = group.members.filter(
          member => member.id !== GlobalConfig.uid
        )
        return {
          ...group,
          members
        }
      })
  }
)

export const pendingAlarmSelector = createSelector(
  [participantAlarmsSelector],
  participantAlarms => {
    return participantAlarms
      .filter(alarm => {
        const participants = AlarmUtils.getAlarmParticipants(
          alarm.type,
          alarm.backupGroup,
          alarm.backupContacts,
          alarm.recipient
        )
        const participant = Utils.getObjectWithId(
          participants,
          GlobalConfig.uid
        )
        const participantAlarmDate = DateTimeUtils.getParticipantAlarmDate(
          alarm,
          participant.order
        )

        // If an alarm has been seen, already responded to or it is in the past, don't show the respond alert
        if (
          participant.seenOn ||
          participant.responseStatus !== Constants.ALARM_RESPONSE_PENDING ||
          participantAlarmDate < Date.now() ||
          alarm.status === false
        ) {
          return false
        }

        return true
      })
      .map(alarm => ({
        ...alarm,
        alarmCategory: Constants.AlarmCategories.PARTICIPANT_ALARM
      }))
  }
)

// Only difference between the pendingAlarmsSelector and alarmsNotRespondedSelector we also consider alarms even if user has seen the alert, an alarm will appear if there is no response from the participant and if it has not yet expired

export const alarmsNotRespondedSelector = createSelector(
  [participantAlarmsSelector],
  participantAlarms => {
    return participantAlarms
      .filter(alarm => {
        const participantAlarmDate = DateTimeUtils.getParticipantAlarmDate(
          alarm,
          alarm.order
        )

        // If alarm is already responded to or it is in the past, don't show the respond alert
        if (
          alarm.responseStatus !== Constants.ALARM_RESPONSE_PENDING ||
          (participantAlarmDate < Date.now() && alarm.repeatType === '') ||
          alarm.status === false
        ) {
          return false
        }

        return true
      })
      .map(alarm => ({
        ...alarm,
        alarmCategory: Constants.AlarmCategories.PARTICIPANT_ALARM
      }))
  }
)

export const alarmsWithUnreadMessagesSelector = createSelector(
  [allAlarmsSelector, getAlarmsConversations],
  (alarms, conversations) => {
    return alarms.filter(alarm => {
      const participants = AlarmUtils.getAlarmParticipants(
        alarm.type,
        alarm.backupGroup,
        alarm.backupContacts,
        alarm.recipient
      )
      const numParticipants = participants.length || 0
      const conversation = conversations[alarm.id]
      return (
        conversation && conversation.unseenMessages > 0 && numParticipants > 0
      )
    })
  }
)

export const missedAlarmsSelector = createSelector(
  [allAlarmsSelector],
  alarms => {
    return alarms.filter(alarm => {
      if (alarm.alarmCategory === Constants.AlarmCategories.MY_ALARM) {
        const alarmAcknowledged =
          AlarmUtils.isCurrentAlarmOccurrenceAcknowledged(alarm)
        const alarmExpired = AlarmUtils.hasAlarmExpired(alarm.id)
        return (
          alarmExpired &&
          !alarmAcknowledged &&
          alarm.creationMode !== Constants.AlarmCreationModes.INSTANT_ALARM
        )
      } else {
        const alarmAcknowledged =
          AlarmUtils.isCurrentAlarmOccurrenceAcknowledged(alarm)
        const alarmExpired = AlarmUtils.hasParticipantAlarmExpired(alarm.id)
        const alarmDenied = alarm.responseStatus === Constants.REJECT_ALARM
        return alarmExpired && !alarmAcknowledged && !alarmDenied
      }
    })
  }
)

export const showDeleteInactiveAlarmsMessageSelector = createSelector(
  [ownAlarmsSelector],
  alarms => {
    const weekOldDate = moment().subtract(15, 'days').valueOf()
    const weekOldAlarms = alarms
      .filter(alarm => !alarm.status)
      .filter(alarm => {
        if (alarm.repeatType === '') {
          return alarm.date < weekOldDate
        } else {
          return alarm.lastUpdatedAt < weekOldDate
        }
      })

    return weekOldAlarms.length >= 20
  }
)

export const inactiveAlarmsCountSelector = createSelector(
  [ownAlarmsSelector],
  alarms => {
    return alarms.filter(alarm => !alarm.status).length
  }
)

export const activeAlarmsCountSelector = createSelector(
  [ownAlarmsSelector, participantAlarmsSelector],
  (ownAlarms, participantAlarms) => {
    const ownAlarmsActiveCount = ownAlarms.filter(alarm => alarm.status).length
    const participantAlarmsActiveCount = participantAlarms.filter(
      alarm => alarm.status && alarm.responseStatus !== Constants.REJECT_ALARM
    )
    return ownAlarmsActiveCount + participantAlarmsActiveCount
  }
)

export const ownAlarmsCountSelector = createSelector(
  [getAlarmsCount],
  alarmsCount => {
    const newPersonalAlarmsCount = objGet(
      alarmsCount,
      `ownAlarms.${Constants.AlarmTypes.CASCADING}.new`,
      0
    )
    const editedPersonalAlarmsCount = objGet(
      alarmsCount,
      `ownAlarms.${Constants.AlarmTypes.CASCADING}.edit`,
      0
    )
    const newGroupAlarmsCount = objGet(
      alarmsCount,
      `ownAlarms.${Constants.AlarmTypes.SIMULTANEOUS}.new`,
      0
    )

    const editedGroupAlarmsCount = objGet(
      alarmsCount,
      `ownAlarms.${Constants.AlarmTypes.SIMULTANEOUS}.edit`,
      0
    )
    const newBuddyAlarmsCount = objGet(
      alarmsCount,
      `ownAlarms.${Constants.AlarmTypes.RECIPIENT}.new`,
      0
    )

    const editedBuddyAlarmsCount = objGet(
      alarmsCount,
      `ownAlarms.${Constants.AlarmTypes.RECIPIENT}.edit`,
      0
    )
    return (
      newPersonalAlarmsCount +
      editedPersonalAlarmsCount +
      newGroupAlarmsCount +
      editedGroupAlarmsCount +
      newBuddyAlarmsCount +
      editedBuddyAlarmsCount
    )
  }
)

export const upgradePurchasedSelector = createSelector(
  [getPurchases, getEnterpriseSubscriptions],
  (purchases, enterpriseSubscriptions) => {
    // If the platform is web or we are in staging environment.
    // return true
    if (
      Platform.OS === 'web' ||
      ConfigManager.getAppEnvironment() === 'Staging'
    ) {
      return true
    }

    if (enterpriseSubscriptions?.length > 0) {
      console.tron.log(
        'Enterprise subscriptions found',
        enterpriseSubscriptions
      )
      return true
    }

    return (
      arrCompact(
        purchases
          .map(purchase => purchase.productId)
          .filter(
            productId =>
              productId === Constants.PREMIUM_UPGRADE_SKU ||
              productId === Constants.MONTHLY_PREMIUM_SUBSCRIPTION_SKU ||
              productId === Constants.QUARTERLY_PREMIUM_SUBSCRIPTION_SKU ||
              productId === Constants.ANNUAL_PREMIUM_SUBSCRIPTION_SKU_OLD_1 ||
              productId === Constants.ANNUAL_PREMIUM_SUBSCRIPTION_SKU ||
              productId === Constants.PREMIUM_LIFETIME_SKU
          )
      ).length > 0
    )
  }
)

export const canCreateNewAlarmDataSelector = createSelector(
  [
    ownAlarmsSelector,
    upgradePurchasedSelector,
    getMaxAllowedAlarms,
    getAppState
  ],
  (ownAlarms, upgradePurchased, maxAllowedAlarms, appState) => {
    const disabledAlarmsCount = ownAlarms.filter(alarm => !alarm.status).length
    return {
      alarms: ownAlarms,
      numAlarms: ownAlarms.length,
      maxAllowedAlarms: maxAllowedAlarms,
      disabledAlarmsCount,
      upgradePurchased,
      isConnected: appState.isConnected,
      isAuthenticated: appState.authenticatedWithFirebase,
      connectedToIap: appState.connectedToIap
    }
  }
)

export const makeAlarmCategoryForAlarmIdSelector = () =>
  createSelector(
    [getAlarmIdFromProps, alarmCategoriesSelector],
    (alarmId, alarmCategories) => {
      const alarmCategory = AlarmUtils.getAlarmCategoryForAlarm(
        alarmCategories,
        alarmId
      )

      return alarmCategory
    }
  )

export const makeAlarmsInCategorySelector = () =>
  createSelector(
    [getIdFromProps, allAlarmsSelector, alarmCategoriesSelector],
    (alarmCategoryId, allAlarms, alarmCategories) => {
      if (alarmCategoryId === Constants.UNCATEGORIZED_ALARM_CATEGORY_ID) {
        const allAlarmIdsInCategories = [].concat(
          ...Object.values(alarmCategories).map(
            alarmCategory => alarmCategory.alarmIds
          )
        )
        const uncategorizedAlarms = allAlarms.filter(
          alarm => !allAlarmIdsInCategories.includes(alarm.id)
        )
        return uncategorizedAlarms
      }

      const alarmCategory = alarmCategories[alarmCategoryId] || {}
      const alarmIds = alarmCategory.alarmIds || []
      const alarmsInCategory = allAlarms.filter(alarm =>
        alarmIds.includes(alarm.id)
      )
      return alarmsInCategory
    }
  )

export const makeAlarmCategorySummaryDataSelector = () =>
  createSelector(
    [
      getIdFromProps,
      allAlarmsSelector,
      missedAlarmsSelector,
      alarmCategoriesSelector
    ],
    (alarmCategoryId, allAlarms, missedAlarms, alarmCategories) => {
      if (alarmCategoryId === Constants.UNCATEGORIZED_ALARM_CATEGORY_ID) {
        const allAlarmIdsInCategories = [].concat(
          ...Object.values(alarmCategories).map(
            alarmCategory => alarmCategory.alarmIds
          )
        )
        const uncategorizedAlarms = allAlarms.filter(
          alarm => !allAlarmIdsInCategories.includes(alarm.id)
        )
        const missedUncategorizedAlarms = missedAlarms.filter(
          alarm => !allAlarmIdsInCategories.includes(alarm.id)
        )
        return {
          numAlarms: uncategorizedAlarms.length,
          numMissedAlarms: missedUncategorizedAlarms.length
        }
      }

      const alarmCategory = alarmCategories[alarmCategoryId] || {}
      const alarmIds = alarmCategory.alarmIds || []
      const alarmsInCategory = allAlarms.filter(alarm =>
        alarmIds.includes(alarm.id)
      )
      const missedAlarmsInCategory = missedAlarms.filter(alarm =>
        alarmIds.includes(alarm.id)
      )

      return {
        numAlarms: alarmsInCategory.length,
        numMissedAlarms: missedAlarmsInCategory.length
      }
    }
  )
export const makeSubscribedAlarmSystemAlertsSelector = () =>
  createSelector(
    [getAlarmIdFromProps, getSystemAlerts],
    (alarmId, systemAlerts) => {
      const subscribedAlarmSystemAlerts = systemAlerts.filter(
        systemAlert =>
          (systemAlert.type === Constants.AlertTypes.SUBSCRIBED_ALARM_EDITED ||
            systemAlert.type ===
              Constants.AlertTypes.SUBSCRIBED_ALARM_DELETED) &&
          systemAlert.subscriberAlarmId === alarmId
      )

      return subscribedAlarmSystemAlerts
    }
  )

export const makeAlarmAcknowledgementsSelector = () =>
  createSelector(
    [getAlarmAcknowledgements, getAlarmIdFromProps],
    (allAlarmAcknowledgements = {}, alarmId) => {
      const alarmAcknowledgements = allAlarmAcknowledgements[alarmId] || {}
      let alarmAcknowledgementsToReturn = []
      Object.keys(alarmAcknowledgements).forEach(occurrenceTimeString => {
        const alarmAcknowledgementsForOccurrence =
          alarmAcknowledgements[occurrenceTimeString] || {}
        Object.keys(alarmAcknowledgementsForOccurrence).forEach(uid => {
          const alarmAcknowledgementsForOccurrenceForUser =
            alarmAcknowledgementsForOccurrence[uid] || []
          alarmAcknowledgementsToReturn = alarmAcknowledgementsToReturn.concat(
            alarmAcknowledgementsForOccurrenceForUser
          )
        })
      })
      return alarmAcknowledgementsToReturn
    }
  )

export const makeAlarmStatusChangesSelector = () =>
  createSelector(
    [getAlarmStatusChanges, getAlarmIdFromProps],
    (allAlarmStatusChanges = {}, alarmId) => {
      const alarmStatusChanges = allAlarmStatusChanges[alarmId] || {}
      return alarmStatusChanges
    }
  )

export const defaultChecklistSelector = createSelector(
  [getChecklists],
  checklists => {
    const defaultChecklist = checklists.find(checklist => checklist.isDefault)

    // If default checklist doesn't exist, return undefined
    if (!defaultChecklist) {
      return defaultChecklist
    }

    // Only consider checklists that are not default
    const sortedChecklists = checklists
      .filter(checklist => !checklist.isDefault)
      .sort((checklist1, checklist2) => {
        return Utils.alphabeticallyCompareObjsBasedOnAPropCI(
          checklist1,
          checklist2,
          'name'
        )
      })

    return {
      ...defaultChecklist,
      checklists: sortedChecklists
    }
  }
)

export const makeChecklistSelector = () =>
  createSelector(
    [getChecklists, getChecklistIdIdFromProps],
    (checklists, checklistId) => {
      let checklist = Utils.getObjectWithId(checklists, checklistId)
      return checklist
    }
  )

export const makeOwnAlarmDetailsSelector = () =>
  createSelector(
    [
      ownAlarmsSelector,
      getAlarmIdFromProps,
      groupListSelector,
      getUserName,
      getUserMobileNumber,
      contactListSelector,
      getConnections
    ],
    (
      alarms,
      alarmId,
      groups,
      username,
      mobileNumber,
      contacts,
      connections
    ) => {
      let alarm = Utils.getObjectWithId(alarms, alarmId)
      if (alarm === undefined) {
        return {}
      }
      if (alarm.backupGroup && alarm.backupGroup.members) {
        const resolvedMembers = resolveGroupMembers(
          alarm.backupGroup.members,
          username,
          mobileNumber,
          contacts,
          connections
        )
        let alarmBackupGroup = {
          ...alarm.backupGroup,
          members: resolvedMembers
        }
        alarm = {
          ...alarm,
          backupGroup: alarmBackupGroup
        }
      }
      // Map individual backup contacts using local data
      if (alarm.backupContacts !== undefined) {
        let alarmBackupContacts = alarm.backupContacts.map(contact =>
          resolveContactName(
            contact,
            username,
            mobileNumber,
            contacts,
            connections
          )
        )
        alarm = {
          ...alarm,
          backupContacts: alarmBackupContacts
        }
      }
      // Map recipient using local data
      if (alarm.recipient) {
        let recipient = resolveContactName(
          alarm.recipient,
          username,
          mobileNumber,
          contacts,
          connections
        )
        alarm = {
          ...alarm,
          recipient
        }
      }

      return {
        ...alarm,
        creatorName: username
      }
    }
  )

export const makeParticipantAlarmDetailsSelector = () =>
  createSelector(
    [
      participantAlarmsSelector,
      getAlarmIdFromProps,
      groupListSelector,
      getUserName,
      getUserMobileNumber,
      contactListSelector,
      getConnections
    ],
    (
      alarms,
      alarmId,
      groups,
      username,
      mobileNumber,
      contacts,
      connections
    ) => {
      let alarm = Utils.getObjectWithId(alarms, alarmId)
      if (alarm === undefined) {
        return {}
      }
      if (alarm.backupGroup && alarm.backupGroup.members) {
        const resolvedMembers = resolveGroupMembers(
          alarm.backupGroup.members,
          username,
          mobileNumber,
          contacts,
          connections
        )
        const alarmBackupGroup = {
          ...alarm.backupGroup,
          members: resolvedMembers
        }
        alarm = {
          ...alarm,
          backupGroup: alarmBackupGroup
        }
      }
      // Map individual backup contacts using local data
      if (alarm.backupContacts !== undefined) {
        let alarmBackupContacts = alarm.backupContacts.map(contact =>
          resolveContactName(
            contact,
            username,
            mobileNumber,
            contacts,
            connections
          )
        )
        alarm = {
          ...alarm,
          backupContacts: alarmBackupContacts
        }
      }
      // Map recipient using local data
      if (alarm.recipient) {
        let recipient = resolveContactName(
          alarm.recipient,
          username,
          mobileNumber,
          contacts,
          connections
        )
        alarm = {
          ...alarm,
          recipient
        }
      }

      const creator = {
        id: alarm.creator,
        name: alarm.creatorName,
        mobileNumber: alarm.creatorMobileNumber
      }
      const resolvedCreator = resolveContactName(
        creator,
        username,
        mobileNumber,
        contacts,
        connections
      )
      return {
        ...alarm,
        creatorName: resolvedCreator.name,
        creatorMobileNumber: resolvedCreator.mobileNumber,
        creatorKnown: resolvedCreator.known
      }
    }
  )

export const makeParticipantAlarmSelector = () =>
  createSelector(
    [participantAlarmsSelector, getAlarmIdFromProps],
    (alarms, alarmId) => {
      let alarm = Utils.getObjectWithId(alarms, alarmId)
      return {
        ...alarm
      }
    }
  )

export const makeOwnAlarmSelector = () =>
  createSelector(
    [ownAlarmsSelector, getAlarmIdFromProps],
    (alarms, alarmId) => {
      let alarm = Utils.getObjectWithId(alarms, alarmId)
      return {
        ...alarm
      }
    }
  )

export const makeRecentlyDeletedAlarmSelector = () =>
  createSelector(
    [getRecentlyDeletedAlarms, getAlarmIdFromProps],
    (alarms, alarmId) => {
      let alarm = Utils.getObjectWithId(alarms, alarmId)
      return {
        ...alarm
      }
    }
  )

export const makeAlarmSelector = () =>
  createSelector(
    [simpleAllAlarmsSelector, getAlarmIdFromProps],
    (alarms, alarmId) => {
      let alarm = Utils.getObjectWithId(alarms, alarmId)
      return {
        ...alarm
      }
    }
  )

export const makeGroupSelector = () =>
  createSelector(
    [groupListSelector, getGroupIdFromProps],
    (groups, groupId) => {
      let group = Utils.getObjectWithId(groups, groupId)
      return {
        ...group
      }
    }
  )

export const makeContactThumbnailSelector = () =>
  createSelector(
    [
      getContacts,
      getConnections,
      getIdFromProps,
      getUserName,
      getUserThumbnail
    ],
    (contacts, connections, id, username, userThumbnailUrl) => {
      const contact =
        Utils.getObjectWithId(connections.concat(contacts), id) ||
        (id === GlobalConfig.uid
          ? {
              id: GlobalConfig.uid,
              name: username,
              images: { avatarThumbnailUrl: userThumbnailUrl }
            }
          : {})

      const avatarThumbnailUrl = objGet(
        contact,
        'images.avatarThumbnailUrl',
        undefined
      )
      return avatarThumbnailUrl ? { uri: avatarThumbnailUrl } : undefined
    }
  )

export const makeAlarmConversationSelector = () =>
  createSelector(
    [getAlarmConversation, contactListSelector, getConnections],
    (alarmConversation, contacts, connections) => {
      const messages = alarmConversation.messages || []
      const computedMessages = arrayCompact(messages)
        .map(message => {
          const userId = message.userId
          const contact = Utils.getObjectWithId(contacts, userId)
          const username = contact ? contact.name : '~' + message.username
          return {
            _id: message.id,
            text: message.text,
            createdAt: new Date(message.createdAt),
            user: {
              _id: userId,
              name: username
            }
          }
        })
        .sort((message1, message2) => {
          return message2.createdAt - message1.createdAt
        })

      const allContacts = contacts.concat(connections)
      const currentlyTypingUsers = alarmConversation.currentlyTypingUsers || []
      const computedCurrentlyTypingUsers = currentlyTypingUsers
        .filter(id => Utils.getIndexOfObjectWithId(allContacts, id) !== -1)
        .map(id => Utils.getObjectWithId(allContacts, id))
        .sort((contact1, contact2) => {
          return Utils.alphabeticallyCompareObjsBasedOnAPropCI(
            contact1,
            contact2,
            'name'
          )
        })
        .map(contact => contact.name)
      return {
        ...alarmConversation,
        messages: computedMessages,
        currentlyTypingUsers: computedCurrentlyTypingUsers
      }
    }
  )

export const makeCommonAlarmsWithContactSelector = () =>
  createSelector(
    [ownAlarmsSelector, participantAlarmsSelector, getContactIdFromProps],
    (ownAlarms, participantAlarms, contactId) => {
      let alarmsInCommon = []
      ownAlarms.forEach(alarm => {
        const participants = AlarmUtils.getAlarmParticipants(
          alarm.type,
          alarm.backupGroup,
          alarm.backupContacts,
          alarm.recipient
        )
        const participant = Utils.getObjectWithId(participants, contactId)
        if (
          participant !== undefined &&
          participant.state !== Constants.ParticipantStates.INACTIVE
        ) {
          alarmsInCommon.push({
            ...alarm,
            alarmCategory: Constants.AlarmCategories.MY_ALARM
          })
        }
      })

      participantAlarms.forEach(alarm => {
        // Don't show backup alarms which will not ring in future
        const currDate = Date.now()
        const { prevDate, nextDate } = getPrevAndNextDatesForAnAlarmWrtDate(
          currDate,
          alarm.date,
          alarm.endDate,
          alarm.repeatType,
          alarm.repeat,
          alarm.creatorTimezone
        )
        if (
          (nextDate ||
            prevDate + GlobalConfig.alarmPreviousOccurrenceThreshold >
              currDate) &&
          alarm.creator === contactId &&
          alarm.status !== false
        ) {
          alarmsInCommon.push({
            ...alarm,
            alarmCategory: Constants.AlarmCategories.PARTICIPANT_ALARM
          })
        }
      })

      return {
        alarmsInCommon
      }
    }
  )

export const enabledAlarmCategoriesSelector = createSelector(
  [alarmCategoriesSelector],
  alarmCategories => {
    return omitBy(
      alarmCategories,
      ({ disabled, id }) =>
        disabled || id === Constants.UNCATEGORIZED_ALARM_CATEGORY_ID
    )
  }
)

export const makeCommonGroupsWithContactSelector = () =>
  createSelector(
    [groupListSelector, getContactIdFromProps],
    (groups, contactId) => {
      let groupsInCommon = []
      groups.forEach(group => {
        if (group.members.findIndex(member => member.id === contactId) !== -1) {
          groupsInCommon.push(group)
        }
      })

      return {
        groupsInCommon
      }
    }
  )

export const makeCommonAlarmsWithGroupSelector = () =>
  createSelector(
    [ownAlarmsSelector, participantAlarmsSelector, getGroupIdFromProps],
    (ownAlarms, participantAlarms, groupId) => {
      let groupAlarms = []
      ownAlarms.forEach(alarm => {
        if (alarm.backupGroup && alarm.backupGroup.id === groupId) {
          groupAlarms.push({
            ...alarm,
            alarmCategory: Constants.AlarmCategories.MY_ALARM
          })
        }
      })

      participantAlarms.forEach(alarm => {
        if (
          alarm.backupGroup === null ||
          alarm.backupGroup.id !== groupId ||
          alarm.state === Constants.ParticipantStates.INACTIVE ||
          alarm.status === false
        ) {
          return
        }

        // Don't show backup alarms which will not ring in future
        const currDate = Date.now()
        const { prevDate, nextDate } = getPrevAndNextDatesForAnAlarmWrtDate(
          currDate,
          alarm.date,
          alarm.endDate,
          alarm.repeatType,
          alarm.repeat,
          alarm.creatorTimezone
        )
        if (
          nextDate ||
          prevDate + GlobalConfig.alarmPreviousOccurrenceThreshold > currDate
        ) {
          groupAlarms.push({
            ...alarm,
            alarmCategory: Constants.AlarmCategories.PARTICIPANT_ALARM
          })
        }
      })

      return {
        groupAlarms
      }
    }
  )

export const makeAllEnabledAlarmsInCategorySelector = () =>
  createSelector([makeAlarmsInCategorySelector()], alarmsInCategory => {
    if (alarmsInCategory.length) {
      return alarmsInCategory.filter(alarm => {
        if (alarm.alarmCategory === Constants.AlarmCategories.MY_ALARM) {
          return alarm.status
        } else {
          const alarmDeniedOrNotResponded =
            alarm.responseStatus === Constants.ACCEPT_ALARM ||
            alarm.responseStatus === Constants.ALARM_RESPONSE_PENDING
          return alarmDeniedOrNotResponded
        }
      })
    }
    return []
  })

export const makeAllDisabledAlarmsInCategorySelector = () =>
  createSelector([makeAlarmsInCategorySelector()], alarmsInCategory => {
    if (alarmsInCategory.length) {
      return alarmsInCategory.filter(alarm => {
        if (alarm.alarmCategory === Constants.AlarmCategories.MY_ALARM) {
          return !alarm.status
        } else {
          const alarmAcceptedOrNotResponded =
            alarm.responseStatus === Constants.REJECT_ALARM ||
            alarm.responseStatus === Constants.ALARM_RESPONSE_PENDING
          return alarmAcceptedOrNotResponded
        }
      })
    }
    return []
  })

export const requiredPermissionsEnabledSelector = createSelector(
  [getAppState, ringerSettingsSelector],
  (appState, ringerSettings) => {
    if (Platform.OS === 'android') {
      return (
        appState.notificationsEnabled &&
        appState.batteryOptimizationDisabled &&
        appState.scheduleExactAlarmsEnabled
      )
    } else if (Platform.OS === 'ios') {
      if (!appState.notificationsEnabled) {
        return false
      } else if (
        !appState.criticalAlertsEnabled &&
        ringerSettings.criticalAlerts
      ) {
        return false
      } else {
        return true
      }
    }
  }
)
