import { doc, getDoc, onSnapshot, updateDoc } from 'firebase/firestore'
import { Constants, I18n } from 'galarm-config'
import { Box, Card, Heading, Select, Spinner, Text } from 'grommet'
import React, { useEffect } from 'react'
import { HorizontalLine } from 'web-components'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import GlobalConfig from '../GlobalConfig'

// Show duration upto 14 days
const incidentsRetentionDurationOptions = [
  { label: I18n.t('noRetention'), value: 0 },
  { label: I18n.t('numDays', { count: 7 }), value: 7 * Constants.MSEC_IN_DAY },
  {
    label: I18n.t('numDays', { count: 14 }),
    value: 14 * Constants.MSEC_IN_DAY
  },
  { label: I18n.t('numDays', { count: 30 }), value: 30 * Constants.MSEC_IN_DAY }
]

const fetchEnterpriseAccount = async ({ queryKey }) => {
  const enterpriseAccountId = queryKey[1]
  const enterpriseAccountDoc = await getDoc(
    doc(GlobalConfig.firestoreDb, 'enterpriseAccounts', enterpriseAccountId)
  )
  return enterpriseAccountDoc.data()
}

const AccountSettings = ({ enterpriseAccountId }) => {
  const queryClient = useQueryClient()
  const { isPending, error, data } = useQuery({
    queryKey: ['enterpriseAccount', enterpriseAccountId],
    queryFn: fetchEnterpriseAccount
  })

  useEffect(() => {
    // Get the data retention duration from Firestore and listen for changes
    const unsubscribe = onSnapshot(
      doc(GlobalConfig.firestoreDb, 'enterpriseAccounts', enterpriseAccountId),
      () =>
        queryClient.invalidateQueries([
          'enterpriseAccount',
          enterpriseAccountId
        ])
    )

    return () => {
      unsubscribe()
    }
  }, [queryClient, enterpriseAccountId])

  const onChangeIncidentsRetentionDuration = duration => {
    updateDoc(
      doc(GlobalConfig.firestoreDb, 'enterpriseAccounts', enterpriseAccountId),
      {
        incidentsRetentionDuration: duration
      }
    )
  }

  if (isPending) {
    return (
      <Box pad="medium" align="center" gap="small">
        <Spinner />
        <Text>{I18n.t('fetchingData')}</Text>
      </Box>
    )
  }

  if (error) {
    return (
      <Box pad="medium" align="center" gap="small">
        <Spinner />
        <Text>{I18n.t('errorFetchingData', { message: error.message })}</Text>
      </Box>
    )
  }

  if (data.incidentsRetentionDuration === undefined) {
    data.incidentsRetentionDuration =
      GlobalConfig.defaultIncidentsRetentionDuration
  }

  return (
    <Box align="center" flex={{ shrink: 0 }}>
      <Heading level={4}>{I18n.t('settings')}</Heading>
      <Card
        pad="medium"
        width="large"
        background="white"
        elevation="none"
        gap="small">
        <Text weight={500} margin={'none'}>
          {I18n.t('dataRetention')}
        </Text>
        <HorizontalLine height="1px" color="mediumTint" />
        <Box
          direction="row"
          margin="small"
          gap="small"
          align="center"
          justify="between">
          <Box flex>
            <Text level={4}>{I18n.t('retainIncidentsForDuration')}</Text>
            <Text size="small" color="darkTint">
              {I18n.t('retainIncidentsForDurationDescription')}
            </Text>
          </Box>
          <Select
            width={{ max: '150px' }}
            alignSelf="start"
            options={incidentsRetentionDurationOptions}
            value={data.incidentsRetentionDuration}
            labelKey="label"
            valueKey={{ key: 'value', reduce: true }}
            onChange={({ option }) => {
              onChangeIncidentsRetentionDuration(option.value)
            }}
          />
        </Box>
      </Card>
    </Box>
  )
}

export default AccountSettings
